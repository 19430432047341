import './scss/index.scss';

import '../../../node_modules/jquery/dist/jquery.min';
import '../../../node_modules/semantic-ui-css/semantic.min';
import '../../../node_modules/semantic-ui-css/components/dropdown.min';
import '../../../node_modules/semantic-ui-css/components/transition.min';
import '../../../node_modules/semantic-ui-css/components/tab.min';
import '../../../node_modules/semantic-ui-css/components/popup.min';
import '../../../node_modules/semantic-ui-css/components/modal.min';
import '../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min'
import './js/ledlabs-add-to-cart.js';
import './js/ledlabs.js';

// Images
import './media/logo.png';
import './media/lighttop.png';
import './media/latestbg.png';
import './media/newsletterbg.png';
import './media/blog-filter.png';
import './media/blog-placeholder.png';
import './media/blog-fav.png';
import './media/blog-views.png';
import './media/blog-comm.png';
import './media/irlogos.png';
import './media/irlogosen.jpg';
import './media/logo-pzpo.png';
import './media/logo-pzpo-gr.png';
import './media/nowe_logo_kio.png';
import './media/nowe_logo_kio_gr.jpg';
import './media/Oswietlenie-LED-LABS.svg';
import './media/logo-ledlabs-white.svg';
import './media/pin.png';
import './media/productplaceholder.png';
import './media/star.png';
import './media/blik.svg';
import './media/logo-b2b.svg';
import './media/payu-logo-white.svg';
import './media/Diament_Forbes_2022_white.svg';
import './media/Gazele_2021_RGB_biala.svg';
import './media/Gazele_2023_RGB_biala.svg';
import './media/9001_eng_tc_p.svg';
import './media/9001_ger_tc_p.svg';
import './media/9001_pol_tc_p.svg';
import './media/nazamowienie.svg';
import './media/sample_b2c_pricing_import.png';
import './media/thumbnailplaceholder.png';
import Swiper, { Autoplay, Navigation, Pagination, Thumbs } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Autoplay, Navigation, Pagination, Thumbs]);

(function ($) {
    $(document).ready(function () {
        $('.user-button.ui.dropdown').dropdown();

        /**
         * Toggle systems list
         */
        $('body').on('click', '.systems-toggler', function() {
            let toggleContent = $(this).data('toggle');

            $('#' + toggleContent ).toggle();
        });
        
        /**
         * Toggle cart summary groups
         */
        $('body').on('click', '.item-group__toggler', function() {
            let toggleContentId = $(this).data('group-toggle');
            let $toggler = $(this);

            $('.item-group__content[data-group-toggle="'+toggleContentId+'"]').toggle(100, function(){
                if( $toggler.find('i.chevron.icon.down').length > 0 ){ 
                    $toggler.find('i.chevron.icon.down').removeClass('down').addClass('up');
                } else if( $toggler.find('i.chevron.icon.up').length > 0 ) {
                    $toggler.find('i.chevron.icon.up').removeClass('up').addClass('down');
                };
            });
        });

        /**
         * Products List - add to cart button
         */
        $('.product-list-item .list-item-add-to-cart, .swiper-container-horizontal .list-item-add-to-cart').on( 'click', function () {
            const $addToCartButton = $(this);
            const url = $addToCartButton.attr('data-href');
            const quantity = $addToCartButton.parent('.addtocart').find('input.amount__count').val();

            const $cartButton =  $('#sylius-cart-button');
//            const $cartPopup =  $('.cart.popup');

            const rollsSize = $addToCartButton.parent('.addtocart').find('input.rolls__size').val();

            //rolls size modal
            if( parseInt(quantity) < parseInt(rollsSize) ){
                let missingQuantity = parseInt(rollsSize) - parseInt(quantity);
                
                $('#rolls-size-updating__modal').modal({
                    closable: false,
                    onDeny: function () {
                    },
                    onShow: function () {
                        $('#rolls-size-updating-missing__modal').html( missingQuantity );
                    },
                    onApprove: function () {
                        $addToCartButton.parent('.addtocart').find('input.amount__count').val( parseInt(rollsSize) );
                    }
                }).modal('show');
                
                return false;
            } else if( parseInt(quantity) > parseInt(rollsSize) ) {
                const quantityRollsSizeModulo = parseInt(quantity) % parseInt(rollsSize);
                
                if( quantityRollsSizeModulo !== 0 && quantityRollsSizeModulo < parseInt(rollsSize) ){
                    let missingQuantity = parseInt(rollsSize) - quantityRollsSizeModulo;
                    
                    $('#rolls-size-updating__modal').modal({
                        closable: false,
                        onDeny: function () {
                        },
                        onShow: function () {
                            $('#rolls-size-updating-missing__modal').html( missingQuantity );
                        },
                        onApprove: function () {
                            let completePackage = parseInt(quantity) + parseInt(missingQuantity);
                            $addToCartButton.parent('.addtocart').find('input.amount__count').val( parseInt(completePackage) );
                        }
                    }).modal('show');
                    
                    return false;
                }
            }

            $addToCartButton.parent('.addtocart').find('.cart__amount').addClass('disabled');
            $addToCartButton.addClass('disabled loading');
            $cartButton.addClass('loading');

            $.ajax({
                method: "POST",
                url: url,
                data: {
                    'quantity': quantity
                },
                dataType: 'json'
            })
            .done(function(response){
                $addToCartButton.removeClass('disabled loading');
                $addToCartButton.parent('.addtocart').find('.cart__amount').removeClass('disabled');
                $cartButton.removeClass('loading');
                $cartButton.html(response.button);
//                $cartPopup.html(response.popup);

                var productObj = {};
                productObj.name = $addToCartButton.data('name');
                productObj.id = $addToCartButton.data('id');
                productObj.price = $addToCartButton.data('price');
                productObj.brand = '';
                productObj.category = $addToCartButton.data('category');
                productObj.variant = '';
                productObj.quantity = parseInt($addToCartButton.parents('.addtocart').find('input.amount__count').val());
                productObj.currency = $addToCartButton.data('currency');
                productObj.channel = $addToCartButton.data('channel');
                
                dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
                dataLayer.push({
                    'event': 'EECaddToCart',
                    'ecommerce': {
                        'currencyCode': productObj.currency,
                        'add': {// 'add' actionFieldObject measures.
                            'products': [{//  adding a product to a shopping cart.
                                    'name': productObj.name,
                                    'id': productObj.id,
                                    'price': productObj.price,
                                    'brand': '',
                                    'category': productObj.category,
                                    'variant': '',
                                    'quantity': productObj.quantity
                                }]
                        }
                    }
                });
                
                //GA4 ecommerce tracking
                dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
                dataLayer.push({
                    event: 'add_to_cart',
                    ecommerce: {
                        currency: productObj.currency,
                        value: parseFloat(productObj.price * parseInt(productObj.quantity)),
                        items: [
                            {
                                item_id: productObj.id,
                                item_name: productObj.name,
                                affiliation: productObj.channel,
                                item_brand: '',
                                item_category: productObj.category,
                                price: parseFloat(productObj.price),
                                quantity: parseInt(productObj.quantity)
                            }
                        ]
                    }
                });

            });

            return false;
        });
        
        /**
         * Product view - add to cart button
         */
        $('#sylius-product-selecting-variant button[type="submit"]').on('click', function(){
            const $addingToCartForm = $('#ledlabs-product-adding-to-cart');
            const quantity = $('#sylius_add_to_cart_cartItem_quantity').val();
            const rollsSize = $('.rolls__size').val();
            
            //rolls size modal
            if( parseInt(quantity) < parseInt(rollsSize) ){
                let missingQuantity = parseInt(rollsSize) - parseInt(quantity);
                
                $addingToCartForm.removeClass('loading');
                
                $('#rolls-size-updating__modal').modal({
                    closable: false,
                    onDeny: function () {
                    },
                    onShow: function () {
                        $('#rolls-size-updating-missing__modal').html( missingQuantity );
                    },
                    onApprove: function () {
                        $addingToCartForm.find('input#sylius_add_to_cart_cartItem_quantity').val( parseInt(rollsSize) );
                    }
                }).modal('show');
                
                return false;
            } else if( parseInt(quantity) > parseInt(rollsSize) ) {
                const quantityRollsSizeModulo = parseInt(quantity) % parseInt(rollsSize);
                
                if( quantityRollsSizeModulo !== 0 && quantityRollsSizeModulo < parseInt(rollsSize) ){
                    let missingQuantity = parseInt(rollsSize) - quantityRollsSizeModulo;
                    
                    $addingToCartForm.removeClass('loading');
                    
                    $('#rolls-size-updating__modal').modal({
                        closable: false,
                        onDeny: function () {
                        },
                        onShow: function () {
                            $('#rolls-size-updating-missing__modal').html( missingQuantity );
                        },
                        onApprove: function () {
                            let completePackage = parseInt(quantity) + parseInt(missingQuantity);
                            $addingToCartForm.find('input#sylius_add_to_cart_cartItem_quantity').val( parseInt(completePackage) );
                        }
                    }).modal('show');
                    
                    return false;
                }
            }
            
        });
        
        /**
         * Cart - updating - rolls size verification
         */
        $('#sylius-cart-update').on('click', function(){
            let errors = 0;
            
            $('.sylius-quantity__wrap').each(function(){
                let rollsSize = parseInt($(this).find('input.rolls__size').val());
                let currentQuantity = parseInt($(this).find('input[type="number"]').val());
                let $quantityWrap = $(this);
                $quantityWrap.removeClass('error');
                
                if( rollsSize > 0 && currentQuantity < rollsSize ){
                    let missingQuantity = rollsSize - currentQuantity;
                    $quantityWrap.addClass('error');
                    
                    errors++;
                } else if( rollsSize > 0 && currentQuantity > rollsSize ){
                    let quantityPackageModulo = currentQuantity % rollsSize;
                    
                    if( quantityPackageModulo !== 0 && quantityPackageModulo < rollsSize ){
                        let missingQuantity = parseInt(rollsSize) - quantityPackageModulo;
                        $quantityWrap.addClass('error');
                        
                        errors++;
                    }
                }
            });
            
            if( errors > 0 ){
                return false;
            }
        });

        /**
         * Products list - product quantity buttons
         */
        $('.product-list-item .amount__more, .swiper-container-horizontal .amount__more').on('click', function(){
            const $amountCountField = $(this).parent('.cart__amount').find('.amount__count');
            let currentQuantity = $(this).parent('.cart__amount').find('.amount__count').val();
            let newQuantity = parseInt(currentQuantity) + 1;

            if( newQuantity > parseInt( $amountCountField.attr('max') ) ) {
                newQuantity = parseInt( $amountCountField.attr('max') );
            }

            $amountCountField.val( newQuantity );

            return false;
        });
        $('.product-list-item .amount__less, .swiper-container-horizontal .amount__less').on('click', function(){
            const $amountCountField = $(this).parent('.cart__amount').find('.amount__count');
            let currentQuantity = $(this).parent('.cart__amount').find('.amount__count').val();
            let newQuantity = parseInt(currentQuantity) - 1;

            if( newQuantity < 1 ) {
                newQuantity = 1;
            }

            $amountCountField.val( newQuantity );

            return false;
        });

        /**
         * Submit become distributor form
         */
        $('#becomedistributor__form').submit(function(e){
            e.preventDefault();

            const form = $(this);
            const url = form.attr('action');

            form.addClass('loading');

            $.ajax({
                type: "POST",
                url: url,
                data: form.serialize(),
                success: function(response)
                {
                    form.removeClass('loading');

                    if( response.status && response.status.submit === 'ok' ){
                        //success
                        form.replaceWith( response.status.content );
                    } else {
                        //errors

                        if( response.status.submit === 'invalid' ){
                            if( response.status.errors['invalid_recaptcha'] !== '' ){
                                form.find('.invalid_recaptcha').html(response.status.errors['invalid_recaptcha']);
                            }
                        }

                        if( response.status.submit === 'system_error' ){
                            form.replaceWith( response.status.content );
                        }
                    }
                }
            });
        });

        /**
         * Submit contact form
         */
        $('#ledlabs-contact-form').submit(function(e){
            e.preventDefault();

            const form = $(this);
            const url = form.attr('action');

            form.addClass('loading');

            $.ajax({
                type: "POST",
                url: url,
                data: form.serialize(),
                success: function(response)
                {
                    form.removeClass('loading');

                    if( response.status && response.status.submit === 'ok' ){
                        //success
                        form.replaceWith( response.status.content );
                    } else {
                        //errors

                        if( response.status.submit === 'invalid' ){
                            if( response.status.errors['invalid_recaptcha'] !== '' ){
                                form.find('.invalid_recaptcha').html(response.status.errors['invalid_recaptcha']);
                            }
                        }

                        if( response.status.submit === 'system_error' ){
                            form.replaceWith( response.status.content );
                        }
                    }
                }
            });
        });

        /**
         * Products list - range filters
         */
        $('.filter-options-ranges input').on('change', function(){
            let relatedOptions = $(this).parents('.filter-options-ranges').data('related-options');
            let rangeValue = $(this).val();
            let rangeArray = rangeValue.split("-");

            if( rangeValue == "" ){ //reset options
                $('#' + relatedOptions + ' input' ).prop('checked', false);
            } else {
                let min = parseInt( rangeArray[0] );
                let max = parseInt( rangeArray[1] );

                $('#' + relatedOptions + ' input' ).prop('checked', false);
                $('#' + relatedOptions + ' input' ).each(function(){
                    if( parseFloat( $(this).data('numeric-value') ) >= min && parseFloat( $(this).data('numeric-value') ) <= max  ) {
                        $(this).prop('checked', true);
                    }
                });
            }
        });

        /**
         * Products list - end cap group filters
         */
        $('.filter-options-groups input').on('change', function(){
            let relatedOptions = $(this).parents('.filter-options-groups').data('related-options');
            let groupValue = $(this).val();

            if( groupValue == ""){ //reset options
                $('#' + relatedOptions + ' input' ).prop('checked', false);
            } else {
                $('#' + relatedOptions + ' input' ).prop('checked', false);
                if( groupValue == "end_cap_shape_with_hole_group"){
                    $('#' + relatedOptions + ' input.choice-group-with-hole').each(function () {
                        $(this).prop('checked', true);
                    });
                } else if (groupValue == "end_cap_shape_without_hole_group") {
                    $('#' + relatedOptions + ' input.choice-group-without-hole' ).each(function(){
                        $(this).prop('checked', true);
                    });
                }
            }
        });

        $('.filter-options-groups input.filter-options-groups-value__without_hole').on('change', function(){
            let relatedOptions = $(this).parents('.filter-options-groups').data('related-options');

            if( $(this).is(':checked') ){
                $('#' + relatedOptions + ' input.choice-group-without-hole' ).each(function(){
                    $(this).prop('checked', true);
                });
            } else {
                $('#' + relatedOptions + ' input.choice-group-without-hole' ).each(function(){
                    $(this).prop('checked', false);
                });
            }
        });

        /**
         * Products list - systems filters - as dropdown
         */
        $('select#options_option_systems').dropdown();

        $('.product__fancybox').fancybox({
            backFocus: false,
            trapFocus: false
        });

        /**
         * Add to cart from product show page - without reload
         */
        $('#ledlabs-product-adding-to-cart').addToCartAjax();

        /**
         * Text content - page break
         */
        $('div[style="page-break-after:always"] > span').html('<i class="content-break-arrow chevron up icon"></i>');

        $('div[style="page-break-after:always"]').each(function(i){
            let currentBreaker = $(this);
            currentBreaker.attr('data-breaker', i);
            let currentContainer = $(this).parent().closest('div');

            currentBreaker.nextAll().wrapAll('<div class="content-wrapp content-wrapp-'+i+'"></div>');
        });

        $('div[style="page-break-after:always"] > span').on('click', function(){
            let currentBreaker = $(this);
            let currentBreakerNr = $(this).parent().data('breaker');

            $('.content-wrapp-'+currentBreakerNr).slideToggle(500, function(){
                if( currentBreaker.hasClass('show-content') ){
                    currentBreaker.removeClass('show-content');
                    currentBreaker.find('i').removeClass('down').addClass('up');
                } else {
                    currentBreaker.addClass('show-content');
                    currentBreaker.find('i').removeClass('up').addClass('down');
                }
            });
        });

        setTimeout(function () {
            $('.content-wrapp').slideToggle(100, function(){
                $('.content-break-arrow').parent().addClass('show-content');
                $('.content-break-arrow').removeClass('up').addClass('down');
            });
        }, 3000);

        /**
         * Checkout - NIP and company - validation
         */
        
        $('body').on('input change', '#sylius_checkout_address_billingAddress_company, #sylius_checkout_address_billingAddress_nip', function(){
            const reg = /^[0-9]{10}$/;
            
            if( $('#sylius_checkout_address_billingAddress_company').val() !== '' && $('#sylius_checkout_address_billingAddress_nip').val() !== '' && reg.test($('#sylius_checkout_address_billingAddress_nip').val()) ){
                $('#sylius_checkout_address_wantInvoice').prop('checked', true).attr('disabled', false);
            } else {
                $('#sylius_checkout_address_wantInvoice').prop('checked', false).attr('disabled', true);
            }
        });
        
        /**
         * Google Tag Manager - listeners
         */
        $('.gtm-product-link').on('click', function () {
            var productObj = {};
            productObj.name = $(this).data('name');
            productObj.id = $(this).data('id');
            productObj.price = $(this).data('price');
            productObj.brand = '';
            productObj.category = $(this).data('category');
            productObj.variant = '';
            productObj.position = '';
            productObj.list = $(this).data('list');
            productObj.url = $(this).attr('href');

            GTMProductClicks(productObj);
        });

        $('button.sylius-cart-remove-button.gtm-remove-item').on('click', function(){

            var productObj = {};
            productObj.name = $(this).data('name');
            productObj.id = $(this).data('id');
            productObj.price = $(this).data('price');
            productObj.brand = '';
            productObj.category = $(this).data('category');
            productObj.variant = '';
            productObj.quantity = parseInt($(this).parents('tr.gtm-item-row').find('.sylius-quantity input').val());
            productObj.currency = $(this).data('currency');
            productObj.channel = $(this).data('channel');
            
            dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
            dataLayer.push({
                'event': 'EECremoveFromCart',
                'ecommerce': {
                    'remove': {// 'remove' actionFieldObject measures.
                        'products': [{//  removing a product to a shopping cart.
                                'name': productObj.name,
                                'id': productObj.id,
                                'price': productObj.price,
                                'brand': productObj.brand,
                                'category': productObj.category,
                                'variant': productObj.variant,
                                'quantity': productObj.quantity
                            }]
                    }
                }
            });
            
            //GA4 ecommerce tracking
            dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
            dataLayer.push({
                event: "remove_from_cart",
                ecommerce: {
                    currency: productObj.currency,
                    value: parseFloat(productObj.price * parseInt(productObj.quantity)),
                    items: [
                        {
                            item_id: productObj.id,
                            item_name: productObj.name,
                            affiliation: productObj.channel,
                            item_brand: productObj.brand,
                            item_category: productObj.category,
                            price: parseFloat(productObj.price),
                            quantity: parseInt(productObj.quantity)
                        }
                    ]
                }
            });
        });

        $('button.gtm-add-to-cart').on('click', function () {
            var productObj = {};
            var $addToCartButton = $(this);
            productObj.name = $addToCartButton.data('name');
            productObj.id = $addToCartButton.data('id');
            productObj.price = $addToCartButton.data('price');
            productObj.brand = '';
            productObj.category = $addToCartButton.data('category');
            productObj.variant = '';
            productObj.quantity = parseInt($addToCartButton.parents('#ledlabs-product-adding-to-cart').find('input#sylius_add_to_cart_cartItem_quantity').val());
            productObj.currency = $addToCartButton.data('currency');
            productObj.channel = $addToCartButton.data('channel');

            dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
            dataLayer.push({
                'event': 'EECaddToCart',
                'ecommerce': {
                    'currencyCode': productObj.currency,
                    'add': {// 'add' actionFieldObject measures.
                        'products': [{//  adding a product to a shopping cart.
                                'name': productObj.name,
                                'id': productObj.id,
                                'price': productObj.price,
                                'brand': '',
                                'category': productObj.category,
                                'variant': '',
                                'quantity': productObj.quantity
                            }]
                    }
                }
            });
            
            //GA4 ecommerce tracking
            dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
            dataLayer.push({
                event: 'add_to_cart',
                ecommerce: {
                    currency: productObj.currency,
                    value: parseFloat(productObj.price * parseInt(productObj.quantity)),
                    items: [
                        {
                            item_id: productObj.id,
                            item_name: productObj.name,
                            affiliation: productObj.channel,
                            item_brand: '',
                            item_category: productObj.category,
                            price: parseFloat(productObj.price),
                            quantity: parseInt(productObj.quantity)
                        }
                    ]
                }
            });
        });

        //GTM - user see categories products list
        GTMProductImpressionsList();

        //GTM - user see sliders products list
        if (document.querySelector('.products-latest-slider')) {
            let wowItem = document.querySelectorAll(".products-latest-slider");
            for (let i = 0; i < wowItem.length; ++i) {
                const iObserver2 = new IntersectionObserver(onObserveLatestSlider, observeOptions);
                iObserver2.observe(wowItem[i]);
            }
        }

        if (document.querySelector('.products-related-slider')) {
            let wowItem = document.querySelectorAll(".products-related-slider");
            for (let i = 0; i < wowItem.length; ++i) {
                const iObserver2 = new IntersectionObserver(onObserveRelatedSlider, observeOptions);
                iObserver2.observe(wowItem[i]);
            }
        }

        //GTM - search result list observe
        /***
         * @todo detect search results response
         */
//        const resultsInput = document.querySelector('input#bitbag_elasticsearch_search_box_query');
//        resultsInput.addEventListener('input', GTMProductImpressionsSearchResults );

//        let resultsTarget = document.querySelector('#products-search > div.results');
//        let onObserveSearchResults = new MutationObserver(function (mutations) {
//            console.info('muuuuuutations');
//            mutations.forEach(function (mutation) {
//                if (mutation.attributeName !== 'style' || mutation.target.className !== 'results transition visible' )
//                    return;
//                var currentValue = mutation.target.style.display;
//                if ( currentValue === "block" && window.getComputedStyle( resultsTarget ).getPropertyValue( 'display' ) !== 'none' ) {
//
//
//                }
//            });
//        });
//
//        onObserveSearchResults.observe(resultsTarget, {
//            attributes: true
//        });

        //GTM - checkout option - shipping
        $('form[name="sylius_checkout_select_shipping"]').on('click', 'button#next-step', function(){
            let step = 3;
            let checkedMethodInput = $('form[name="sylius_checkout_select_shipping"]').find('input[type="radio"]:checked');
            let checkoutOption = checkedMethodInput.parents('div.item').find('div.content label').text();

            GTMOnCheckoutOption(step, checkoutOption);
        });

        //GTM - checkout option - payment
        $('form[name="sylius_checkout_select_payment"]').on('click', 'button#next-step', function(){
            let step = 4;
            let checkedMethodInput = $('form[name="sylius_checkout_select_payment"]').find('input[type="radio"]:checked');
            let checkoutOption = checkedMethodInput.parents('div.item').find('div.content label').text();

            GTMOnCheckoutOption(step, checkoutOption);
        });
        
        //GA4 - detect add to wishlist item and send to GA4
        $('.gtm-add-to-wishlist').on('click', function(){
            var $variant = $(this);
            
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
                event: "add_to_wishlist",
                ecommerce: {
                    currency: $variant.data('currency'),
                    value: $variant.data('price'),
                    items: [
                        {
                            item_id: $variant.data('id'),
                            item_name: $variant.data('product-name'),
                            affiliation: $variant.data('affiliation'),
                            item_category: $variant.data('category'),
                            price: $variant.data('price'),
                            quantity: 1
                        }
                    ]
                }
            });
        });

        //privacy policy loading full content
        $('#cookie_info_read_more').on('click', function(){

            $('#cookie_info_full').show().addClass('loading');

            $.ajax({
                method: "POST",
                url: '/ledlabsfullprivacypolicy',
                dataType: 'json'
            })
            .done(function (response) {
                if (response['result']) {
                    $('#cookie_info_full').html(response['result']);
                    $('#cookie_info_full').removeClass('loading');
                }
            });


            return false;
        });

        /**
         * Copy promotion code to the Clipboard
         */
        $('body').on('click', '.promotion-code-copy__button', function(){
            let $copyButton = $(this);
            let promotionCode = $copyButton.data('promotion-code');

            copyPromotionCode(promotionCode, $copyButton);
        });

        $('.promotion-code-copy__button').popup({
            on: 'manual',
            onVisible: function(e){
                $(e).delay(1500)
                    .queue(function() {
                        $(this).popup('hide').dequeue();
                    });
            }
        });

        async function copyPromotionCode(promoCode, button) {
            try {
                await navigator.clipboard.writeText(promoCode);
                button.popup('show');
            } catch (err) {
                console.error('Failed to copy: ', err);
            }
        }
        
        /*** project - delete confirmation***/
        $('[data-requires-confirmation-project]').requireConfirmationProject();
        
        /**
         * Shared projects - children accordion
         */
        $('.projects-children__accordion').on('click', '.projects-child-toggler__button', function() {
            let projectId = $(this).data('project-id');
            let projectOwnerType = $(this).data('owner-type');
            let url = $(this).data('toggler-save');
            let togglerButtonLabel = $(this).find('.projects-child-toggler__label');

            $.ajax({
                method: "POST",
                url: url,
                data: {
                    'id': projectId,
                    'ownertype': projectOwnerType
                },
                dataType: 'json'
            })
            .done(function (response) {
                togglerButtonLabel.text(response['content']);
            });
        });
        
        /**
         * Shared projects - labels popup
         */
        $('.project-label__popup').popup();
        
        /**
         * Invoices - state popup
         */
        $('.invoice-state__popup').popup();
        
        /**
         * Upload distributor logo - content
         */
        $('#app_shop_user_logo_userLogo_file').on('change', function(e){
            if( this.files.length > 0 ){
                    $('#app_shop_user_logo_userLogo_file__content').text(this.files.item(0).name);
            } else {
                $('#app_shop_user_logo_userLogo_file__content').text($('#app_shop_user_logo_userLogo_file__content').data('empty-file'));
            }
        });
        
        /**
         * Upload notes files file - content
         */
        var $collectionHolder = $('#sylius_checkout_complete_notesFiles');
        $collectionHolder.data('index', $collectionHolder.find('input[type="file"]').length);
        $collectionHolder.on('DOMNodeInserted', function () {
            $('.app-shop-notesFiles-file input[type="file"]').on('change', function(e){
                let $scope = $(this).parents('div[data-form-collection="item"]');

                if( this.files.length > 0 ){
                    $scope.find('span.sylius_checkout_complete_notesFiles_file__content').text(this.files.item(0).name);
                } else {
                    $scope.find('span.sylius_checkout_complete_notesFiles_file__content').text($scope.find('span.sylius_checkout_complete_notesFiles_file__content').data('empty-file'));
                }
            });
        });
        
        /**
         * Set address fields read only - for Company in PL
         */
        
        if( $('#sylius_checkout_address_billingAddress_addressType').length > 0 ){
            checkGusBlockadeByAddressType($('#sylius_checkout_address_billingAddress_addressType'));
            
            $('#sylius_checkout_address_billingAddress_addressType').on('change', function () {
                checkGusBlockadeByAddressType($(this));
            });
        }
        
        if( $('#sylius_checkout_address_billingAddress_countryCode').length > 0 ){
            checkGusBlockadeByCountryCode($('#sylius_checkout_address_billingAddress_countryCode'));
            
            $('#sylius_checkout_address_billingAddress_countryCode').on('change', function () {
                checkGusBlockadeByCountryCode($(this));
                
                if( $(this).find('option:selected').data('has-provinces') == "1" ){
                    $('form[name="sylius_checkout_address"]').find('.province-container').addClass('required');
                } else {
                    $('form[name="sylius_checkout_address"]').find('.province-container').removeClass('required');
                }
            });
        }
        
        if( $('#sylius_checkout_address_shippingAddress_countryCode').length > 0 ){
            checkNIPCountryByCountryCode($('#sylius_checkout_address_shippingAddress_countryCode'));
            
            $('#sylius_checkout_address_shippingAddress_countryCode').on('change', function () {
                checkNIPCountryByCountryCode($(this));
                
                if( $(this).find('option:selected').data('has-provinces') == "1" ){
                    $('form[name="sylius_checkout_address"]').find('.province-container').addClass('required');
                } else {
                    $('form[name="sylius_checkout_address"]').find('.province-container').removeClass('required');
                }
            });
        }
        
        if( $('#sylius_address_addressType').length > 0 ){
            if( $('#sylius_address_addressType').parents('form').hasClass('billing-address-fields__wrap') ){
                checkGusBlockadeByAddressType($('#sylius_address_addressType'), '#sylius_address_countryCode', false);

                $('#sylius_address_addressType').on('change', function () {
                    checkGusBlockadeByAddressType($(this), '#sylius_address_countryCode', false);
                });
            } else if($('#sylius_address_addressType').parents('form[action="/account/address-book/add"]')){ //gus blockade for new address in book
                checkGusBlockadeByAddressType($('#sylius_address_addressType'), '#sylius_address_countryCode', false);
                
                $('#sylius_address_addressType').on('change', function () {
                    checkGusBlockadeByAddressType($(this), '#sylius_address_countryCode', false);
                });
            }
        }
        
        if( $('#sylius_address_countryCode').length > 0 ){
            if( $('#sylius_address_countryCode').find('option:selected').data('has-provinces') == "1" ){
                $('form[name="sylius_address"]').find('.province-container').addClass('required');
            } else {
                $('form[name="sylius_address"]').find('.province-container').removeClass('required');
            }
            
            if( $('#sylius_address_countryCode').parents('form').hasClass('billing-address-fields__wrap') ){
                checkGusBlockadeByCountryCode($('#sylius_address_countryCode'), '#sylius_address_addressType', false);

                $('#sylius_address_countryCode').on('change', function () {
                    checkGusBlockadeByCountryCode($(this), '#sylius_address_addressType', false);
                });
            } else if($('#sylius_address_countryCode').parents('form[action="/account/address-book/add"]')){ //gus blockade for new address in book
                checkGusBlockadeByCountryCode($('#sylius_address_countryCode'), '#sylius_address_addressType', false);
                
                $('#sylius_address_countryCode').on('change', function () {
                    checkGusBlockadeByCountryCode($(this), '#sylius_address_addressType', false);
                });
            }
            
            checkNIPCountryByCountryCode($('#sylius_address_countryCode'));
            
            $('#sylius_address_countryCode').on('change', function () {
                checkGusBlockadeByCountryCode($(this), '#sylius_address_addressType', false);
            });
        }
        
        if( $('#app_distributor_registration_distributorAddress_addressType').length > 0 ){
            checkGusBlockadeByAddressType($('#app_distributor_registration_distributorAddress_addressType'), '#app_distributor_registration_distributorAddress_countryCode', false);
            
            $('#app_distributor_registration_distributorAddress_addressType').on('change', function () {
                checkGusBlockadeByAddressType($(this), '#app_distributor_registration_distributorAddress_countryCode', false);
            });
        }
        
        if( $('#app_distributor_registration_distributorAddress_countryCode').length > 0 ){
            checkGusBlockadeByCountryCode($('#app_distributor_registration_distributorAddress_countryCode'), '#app_distributor_registration_distributorAddress_addressType', false);
            
            $('#app_distributor_registration_distributorAddress_countryCode').on('change', function () {
                checkGusBlockadeByCountryCode($(this), '#app_distributor_registration_distributorAddress_addressType', false);
            });
        }
        
        if( $('#app_distributor_from_shop_user_customer_distributorAddress_addressType').length > 0 ){
            checkGusBlockadeByAddressType($('#app_distributor_from_shop_user_customer_distributorAddress_addressType'), '#app_distributor_from_shop_user_customer_distributorAddress_countryCode', false);
            
            $('#app_distributor_from_shop_user_customer_distributorAddress_addressType').on('change', function () {
                checkGusBlockadeByAddressType($(this), '#app_distributor_from_shop_user_customer_distributorAddress_countryCode', false);
            });
        }
        
        if( $('#app_distributor_from_shop_user_customer_distributorAddress_countryCode').length > 0 ){
            checkGusBlockadeByCountryCode($('#app_distributor_from_shop_user_customer_distributorAddress_countryCode'), '#app_distributor_from_shop_user_customer_distributorAddress_addressType', false);
            
            $('#app_distributor_from_shop_user_customer_distributorAddress_countryCode').on('change', function () {
                checkGusBlockadeByCountryCode($(this), '#app_distributor_from_shop_user_customer_distributorAddress_addressType', false);
            });
        }
        
        function checkGusBlockadeByAddressType(addressTypeSelect, countryCodeSelect = '#sylius_checkout_address_billingAddress_countryCode', multiple = true)
        {
            let $scope = $(addressTypeSelect).parents('form');
            
            if( multiple === true ){
                $scope = $(addressTypeSelect).parents('#sylius-billing-address');
            }
            
            let addressType = $(addressTypeSelect).val();
            let countryCode = $(countryCodeSelect).val();
            
            if( addressType == 'company' && countryCode == 'PL' ){ //set blockade
                if( !$scope.hasClass('create-address-book__form') ){
                    $scope.find('.gus-readonly').addClass('gus-disabled').prop('readonly', true);
                }
                
                $scope.find('.address-nip-country-code').val(countryCode);
            } else { //remove blockade
                
                if(addressType == 'person'){ //clear company and nip fields
                    $scope.find('input[name*="[nip]"]').val("");
                    $scope.find('input[name*="[company]"]').val("");
                }
                
                if( countryCode == 'GR' ){
                    $scope.find('.address-nip-country-code').val("EL");
                } else {
                    $scope.find('.address-nip-country-code').val(countryCode);
                }
                
                $scope.find('.gus-readonly').removeClass('gus-disabled').prop('readonly', false);
            }
        }
        
        function checkGusBlockadeByCountryCode(countryCodeSelect, addressTypeSelect = '#sylius_checkout_address_billingAddress_addressType', multiple = true)
        {
            let $scope = $(countryCodeSelect).parents('form');
            
            if( multiple === true ){
                $scope = $(countryCodeSelect).parents('#sylius-billing-address');
            }
            
            let addressType = $(addressTypeSelect).val();
            let countryCode = $(countryCodeSelect).val();
            
            //change NIP/VAT number label
            if( countryCode == 'PL' ){
                $scope.find('.address-nip-label__vat').addClass('hidden-field');
                $scope.find('.address-nip-label__pl').removeClass('hidden-field');
            } else {
                $scope.find('.address-nip-label__pl').addClass('hidden-field');
                $scope.find('.address-nip-label__vat').removeClass('hidden-field');
            }
            
            if( addressType == 'company' && countryCode == 'PL' ){ //set blockade
                if( !$scope.hasClass('create-address-book__form') ){
                    $scope.find('.gus-readonly').addClass('gus-disabled').prop('readonly', true);
                }
                
                $scope.find('.address-nip-country-code').val(countryCode);
            } else { //remove blockade
                $scope.find('.gus-readonly').removeClass('gus-disabled').prop('readonly', false);
                
                if( countryCode == 'GR' ){
                    $scope.find('.address-nip-country-code').val("EL");
                } else {
                    $scope.find('.address-nip-country-code').val(countryCode);
                }
            }
            
            if( $(countryCodeSelect).find('option:selected').data('has-provinces') == "1" ){
                $scope.find('.province-container').addClass('required');
            } else {
                $scope.find('.province-container').removeClass('required');
            }
        }
        
        function checkNIPCountryByCountryCode(countryCodeSelect, addressTypeSelect = '#sylius_checkout_address_shippingAddress_addressType', multiple = true)
        {
            let $scope = $(countryCodeSelect).parents('form');
            
            if( multiple === true ){
                $scope = $(countryCodeSelect).parents('#sylius-shipping-address');
            }
            
            let countryCode = $(countryCodeSelect).val();
            
            if( countryCode === 'GR' ){
                $scope.find('.address-nip-country-code').val('EL');
            } else {
                $scope.find('.address-nip-country-code').val(countryCode);
            }
        }
        
        /**
         * Auto select default billing address
         */
        if( $('#sylius-billing-address .address-book-select').length > 0 ){
            var firstBillingAddress = $('#sylius-billing-address .address-book-select .menu .item:first-child');
            firstBillingAddress.click();
        }
        
        /**
         * Auto select default shipping address
         */
        $('#sylius_checkout_address_differentShippingAddress').on('change', function(){
            
            if ($('#sylius-shipping-address .address-book-select').length > 0 && $(this).is(':checked')) {
                var defaultShippingAddress = $('#sylius-shipping-address .address-book-select .menu .item.address-item-shipping-default');

                if (defaultShippingAddress.length > 0) {
                    defaultShippingAddress.click();
                }
            }
        });
        
        /**
         * GUS - get data by NIP
         */
        $('.billing-address-fields__wrap input[name*="[nip]"], .address-book-create__nip-wrap input[name*="[nip]"], .address-wantsbedistributor__nip-wrap input[name*="[nip]"]').on('change', function(){
            let scope = $(this).parents('.billing-address-fields__wrap');
            
            if( $(this).parents('form[action="/account/address-book/add"]').length > 0 ){
                scope = $(this).parents('form[action="/account/address-book/add"]');
            }
            
            if( $(this).parents('form[action="/account/wantbedistributor"]').length > 0 ){
                scope = $(this).parents('form[action="/account/wantbedistributor"]');
            }
            
            let addressType = scope.find('select[name*="[addressType]"]').val();
            let countryCode = scope.find('select[name*="[countryCode]"]').val();
            
            let nip = $(this).val();
            
            if( addressType == 'company' && countryCode == 'PL' ){
                nip = nip.replace(/[^\d]/g, "");
            } else {
                nip = nip.replace(/[^a-z0-9]/gi, "");
            }
            
            $(this).val(nip);
            
            if( addressType == 'company' && countryCode == 'PL' ){
                scope.parents('form').addClass('loading');
                scope.addClass('loading');
                
                $.ajax({
                        method: "POST",
                        url: '/ledlabsgusgetdatabynip',
                        data: {
                            'nip': nip
                        },
                        dataType: 'json'
                    })
                    .done(function(response){
                        
                        if( response.status == 1 ){
                            if( !scope.hasClass('create-address-book__form') ){
                                scope.find('.gus-readonly').val('').addClass('gus-disabled'); //clear current values
                            } else {
                                scope.find('.gus-readonly').val(''); //clear current values
                            }
                    
                            scope.find('[name*="[company]"]').val(response.data.company);
                            scope.find('[name*="[provinceCode]"]').val(response.data.provinceCode);
                            scope.find('[name*="[street]"]').val(response.data.street);
                            scope.find('[name*="[houseNumber]"]').val(response.data.houseNumber);
                            scope.find('[name*="[apartmentNumber]"]').val(response.data.apartmentNumber);
                            scope.find('[name*="[city]"]').val(response.data.city);
                            scope.find('[name*="[postcode]"]').val(response.data.postCode);
                            
                            if (response.data.provinceCode === null || response.data.provinceCode.trim() === ''
                                    || response.data.street === null || response.data.street.trim() === ''
                                    || response.data.houseNumber === null || response.data.houseNumber.trim() === ''
                                    || response.data.city === null || response.data.city.trim() === ''
                                    || response.data.postCode === null || response.data.postCode.trim() === '') {
                                scope.find('.gus-readonly').not('[name*="[company]"]').removeClass('gus-disabled').prop('readonly', false);
                            }
                        } else {
                            scope.find('[name*="[company]"]').val("");
                            scope.find('[name*="[provinceCode]"]').val("");
                            scope.find('[name*="[street]"]').val("");
                            scope.find('[name*="[houseNumber]"]').val("");
                            scope.find('[name*="[apartmentNumber]"]').val("");
                            scope.find('[name*="[city]"]').val("");
                            scope.find('[name*="[postcode]"]').val("");
                        }
                        
                        scope.parents('form').removeClass('loading');
                        scope.removeClass('loading');
                    })
                    .always(function(){
                        scope.parents('form').removeClass('loading');
                        scope.removeClass('loading');
                    })
                    ;
            }
            
        });

        if($('input[name="newsletter_token"]').length > 0 ){
            
            const tokenUrl = $('input[name="newsletter_token"]').data('token-url');
            
            $.ajax({
                method: "POST",
                url: tokenUrl,
                dataType: 'json'
            })
            .done(function (response) {
                if( response['token'] ){
                    $('input[name="newsletter_token"]').val(response['token']);
                }
            });
        }
        
        if ($('input[name*="newsletterToken"]').length > 0) {

            const tokenUrl = $('input[name*="newsletterToken"]').data('token-url');

            $.ajax({
                method: "POST",
                url: tokenUrl,
                dataType: 'json'
            })
                    .done(function (response) {
                        if (response['token']) {
                            $('input[name*="newsletterToken"]').val(response['token']);
                        }
                    });
        }
    });
    
    $.fn.extend({
        requireConfirmationProject() {
            this.each((idx, el) => {
                $(el).on('click', (evt) => {
                    evt.preventDefault();

                    const actionButton = $(evt.currentTarget);
                    const checkUrl = actionButton.data('check-url');
                    
                    actionButton.addClass('loading');

                    if (actionButton.is('a')) {
                        $('#confirmation-button').attr('href', actionButton.attr('href'));
                    }

                    if (actionButton.is('button')) {
                        $('#confirmation-button').on('click', (event) => {
                            event.preventDefault();

                            actionButton.closest('form').submit();
                        });
                    }
                    
                    $.ajax({
                        method: "POST",
                        url: checkUrl,
                        dataType: 'json'
                    })
                    .done(function (response) {
                        if( response['used'] == 0 ){
                            $('#confirmation-modal').modal('show');
                        } else {
                            $('#confirmation-project-modal').modal('show');
                        }
                        
                        actionButton.removeClass('loading');
                    });

                });
            });
        },
    });

    /**
     * GTM Products Sliders Observer
     */
    let observeOptions = {
        root: null,
        margin: '0px',
        threshold: 0.1
    };

    let onObserveLatestSlider = function (entries, observer) {
        for (let i = 0; i < entries.length; ++i) {
            let el = entries[i].target;

            if (entries[i].isIntersecting) {
                if (!el.classList.contains("wow-loaded")) {
                    el.classList.add('wow-loaded');

                    GTMProductImpressionsSliders('Slider z najnowszymi produktami');
                }
            }
        }
    };

    let onObserveRelatedSlider = function (entries, observer) {
        for (let i = 0; i < entries.length; ++i) {
            let el = entries[i].target;

            if (entries[i].isIntersecting) {
                if (!el.classList.contains("wow-loaded")) {
                    el.classList.add('wow-loaded');

                    GTMProductImpressionsSliders('Slider z powiązanymi produktami');
                }
            }
        }
    };

    /**
     * Megamenu taxons tabs
     */
    let $latestActiveTab = null;
    $('.megamenu__tabs > .item').on('mouseover', function () {
        const $tab = $(this);
        const tabName = $tab.data('tab');

        if ($latestActiveTab) {
            $latestActiveTab.removeClass('active');
        }

        $tab.addClass('active');
        $latestActiveTab = $tab;

        $.tab('change tab', tabName);
    });


    /**
     * Sliders
     */
    const blogLatestSliderNews = new Swiper('.blog-latest-slider-news', {
        slidesPerView: 2,
        spaceBetween: 20,
        watchSlidesVisibility: true,
        navigation: {
            prevEl: '.blog-latest-slider-news__prev',
            nextEl: '.blog-latest-slider-news__next',
        },
        breakpoints: {
            1300: {
                slidesPerView: 5
            },
            1200: {
                slidesPerView: 4
            }
        }
    });

    const blogLatestSliderArticles = new Swiper('.blog-latest-slider-articles', {
        slidesPerView: 2,
        spaceBetween: 20,
        watchSlidesVisibility: true,
        navigation: {
            prevEl: '.blog-latest-slider-articles__prev',
            nextEl: '.blog-latest-slider-articles__next',
        },
        breakpoints: {
            1300: {
                slidesPerView: 5
            },
            1200: {
                slidesPerView: 4
            }
        }
    });

    const productsLatestSlider = new Swiper('.products-latest-slider', {
        slidesPerView: 2,
        spaceBetween: 20,
        watchSlidesVisibility: true,
        navigation: {
            prevEl: '.products-latest-slider__prev',
            nextEl: '.products-latest-slider__next',
        },
        breakpoints: {
            1300: {
                slidesPerView: 5
            },
            1200: {
                slidesPerView: 4
            }
        }
    });

    const productsRelatedSlider = new Swiper('.products-related-slider', {
        slidesPerView: 2,
        spaceBetween: 20,
        watchSlidesVisibility: true,
        navigation: {
            prevEl: '.products-related-slider__prev',
            nextEl: '.products-related-slider__next',
        },
        breakpoints: {
            1300: {
                slidesPerView: 5
            },
            1200: {
                slidesPerView: 4
            }
        }
    });
    
    const productsRecommendedSlider = new Swiper('.products-recommended-slider', {
        slidesPerView: 3,
        spaceBetween: 20,
        slidesPerColumnFill: 'row',
        watchSlidesVisibility: true,
        navigation: {
            prevEl: '.products-recommended-slider__prev',
            nextEl: '.products-recommended-slider__next',
        }
    });
    
    $('.products-gallery-slider').each(function (i) {
        let productsGallerySlider = new Swiper($(this)[0], {
            slidesPerView: 2,
            spaceBetween: 20,
            watchSlidesVisibility: true,
            navigation: {
                prevEl: $(".products-gallery-slider__prev")[i],
                nextEl: $(".products-gallery-slider__next")[i],
            },
            breakpoints: {
                1300: {
                    slidesPerView: 5
                },
                1200: {
                    slidesPerView: 3
                }
            }
        });
    });

    $('.products-gallery-in-content-slider').each(function (i) {
        let productsGalleryInContentSlider = new Swiper($(this)[0], {
            slidesPerView: 2,
            spaceBetween: 20,
            watchSlidesVisibility: true,
            navigation: {
                prevEl: $(".products-gallery-in-content-slider__prev")[i],
                nextEl: $(".products-gallery-in-content-slider__next")[i],
            },
            breakpoints: {
                1300: {
                    slidesPerView: 3
                },
                1200: {
                    slidesPerView: 2
                }
            }
        });
    });

    $('.articles-gallery-slider').each(function (i) {
        let articlesGallerySlider = new Swiper($(this)[0], {
            slidesPerView: 2,
            spaceBetween: 20,
            watchSlidesVisibility: true,
            navigation: {
                prevEl: $(".articles-gallery-slider__prev")[i],
                nextEl: $(".articles-gallery-slider__next")[i],
            },
            breakpoints: {
                1300: {
                    slidesPerView: 5
                },
                1200: {
                    slidesPerView: 3
                }
            }
        });
    });

    $('.articles-gallery-in-content-slider').each(function (i) {
        let articlesGalleryInContentSlider = new Swiper($(this)[0], {
            slidesPerView: 2,
            spaceBetween: 20,
            watchSlidesVisibility: true,
            navigation: {
                prevEl: $(".articles-gallery-in-content-slider__prev")[i],
                nextEl: $(".articles-gallery-in-content-slider__next")[i],
            },
            breakpoints: {
                1300: {
                    slidesPerView: 3
                },
                1200: {
                    slidesPerView: 2
                }
            }
        });
    });

    let loop = true;

    if( $('.homepage-slider .swiper-slide').length === 1 ){
        loop = false;
    }

    const homepageSlider = new Swiper('.homepage-slider', {
        slidesPerView: 1,
        watchSlidesVisibility: true,
        loop: loop,
        navigation: {
            prevEl: '.homepage-slider__prev',
            nextEl: '.homepage-slider__next',
        },
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        },
        on: {
            afterInit: function(swiper){
                if ((swiper.params.loop && swiper.slides.length === 3)
                  || (!swiper.params.loop && swiper.slides.length === 1)) {
//                    swiper.autoplay.stop();
//                    swiper.loopDestroy();
                    swiper.navigation.nextEl.style.display = 'none';
                    swiper.navigation.prevEl.style.display = 'none';
                }
            },
            slideChange: function(swiper){
                let index_currentSlide = swiper.activeIndex;
                let currentSlide = swiper.slides[index_currentSlide];
                let arrowsColor = $(currentSlide).data('arrows-color');
                if (typeof arrowsColor !== 'undefined') {
                    $('.homepage-slider__prev').css('color', arrowsColor );
                    $('.homepage-slider__next').css('color', arrowsColor );
                }
            }
        }
    });

    // Product images sliders
    Array.prototype.forEach.call(document.querySelectorAll('.product-images-gallery'), gallery => {
        const thumbsContainer = gallery.querySelector('.products-images-slider-thumbs');
        const mainContainer = gallery.querySelector('.products-images-slider');
        let productsImagesSliderThumbs = null;

        if (thumbsContainer) {
            productsImagesSliderThumbs = new Swiper(thumbsContainer, {
                spaceBetween: 10,
                slidesPerView: 4,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                freeMode: true,
                breakpoints: {
                    1300: {
                        direction: 'vertical',
                    },
                },
            });
        }

        if (mainContainer) {
            new Swiper(mainContainer, {
                slidesPerView: 1,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                pagination: {
                    el: gallery.querySelector('.products-images-slider__pagination'),
                    type: 'fraction',
                },
                navigation: {
                    prevEl: gallery.querySelector('.products-images-slider__prev'),
                    nextEl: gallery.querySelector('.products-images-slider__next'),
                },
                thumbs: { swiper: productsImagesSliderThumbs },
            });
        }
    });
    
    let loopBannerPlatform = true;
    
    if( $('.banner-platform__slider .swiper-slide').length === 1 ){
        loopBannerPlatform = false;
    }
    
    const bannerPlatformSlider = new Swiper('.banner-platform__slider', {
        slidesPerView: 1,
        watchSlidesVisibility: true,
        loop: loopBannerPlatform,
        navigation: {
            prevEl: '.banner-platform__slider__prev',
            nextEl: '.banner-platform__slider__next',
        },
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        },
        on: {
            afterInit: function(swiper){
                if ((swiper.params.loop && swiper.slides.length === 3)
                  || (!swiper.params.loop && swiper.slides.length === 1)) {
                    swiper.navigation.nextEl.style.display = 'none';
                    swiper.navigation.prevEl.style.display = 'none';
                }
            },
            slideChange: function(swiper){
                let index_currentSlide = swiper.activeIndex;
                let currentSlide = swiper.slides[index_currentSlide];
                let arrowsColor = $(currentSlide).data('arrows-color');
                if (typeof arrowsColor !== 'undefined') {
                    $('.banner-platform__slider__prev').css('color', arrowsColor );
                    $('.banner-platform__slider__next').css('color', arrowsColor );
                }
            }
        }
    });
    
    let loopBannerConfigurator = true;
    
    if( $('.banner-configurator__slider .swiper-slide').length === 1 ){
        loopBannerConfigurator = false;
    }
    
    const bannerConfiguratorSlider = new Swiper('.banner-configurator__slider', {
        slidesPerView: 1,
        watchSlidesVisibility: true,
        loop: loopBannerConfigurator,
        navigation: {
            prevEl: '.banner-configurator__slider__prev',
            nextEl: '.banner-configurator__slider__next',
        },
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        },
        on: {
            afterInit: function(swiper){
                if ((swiper.params.loop && swiper.slides.length === 3)
                  || (!swiper.params.loop && swiper.slides.length === 1)) {
                    swiper.navigation.nextEl.style.display = 'none';
                    swiper.navigation.prevEl.style.display = 'none';
                }
            },
            slideChange: function(swiper){
                let index_currentSlide = swiper.activeIndex;
                let currentSlide = swiper.slides[index_currentSlide];
                let arrowsColor = $(currentSlide).data('arrows-color');
                if (typeof arrowsColor !== 'undefined') {
                    $('.banner-configurator__slider__prev').css('color', arrowsColor );
                    $('.banner-configurator__slider__next').css('color', arrowsColor );
                }
            }
        }
    });

    /**
     * Toggling mobile navigation container and mobile menu expanding
     */
    class mobileNavHandler {
        constructor({
                        button,
                        container,
                        classOpen = 'open',
                        iconSelector = '.icon',
                        iconClassOpen = 'bars',
                        iconClassClose = 'close',
                        onOpen,
                        onClose,
                    }) {
            this.button = button;
            this.container = container;
            this.classOpen = classOpen;
            if(button){
                this.iconEl = iconSelector && button.querySelector(iconSelector);
            } else {
                this.iconEl = iconSelector;
            }
            this.iconClassOpen = iconClassOpen;
            this.iconClassClose = iconClassClose;
            this.visible = false;
            this.onOpen = onOpen;
            this.onClose = onClose;

            if(button){
                this.button.addEventListener('click', this.toggle.bind(this));
            }
        }

        toggle() {
            if (this.visible) {
                this.close();
            } else {
                this.open();
            }
        }

        open() {
            this.visible = true;
            this.button.classList.add(this.classOpen);
            this.container.classList.add(this.classOpen);

            if (this.iconEl) {
                this.iconEl.classList.remove(this.iconClassOpen);
                this.iconEl.classList.add(this.iconClassClose);
            }
            if (typeof this.onOpen === 'function') this.onOpen(this);
        }

        close() {
            this.visible = false;
            this.button.classList.remove(this.classOpen);
            this.container.classList.remove(this.classOpen);

            if (this.iconEl) {
                this.iconEl.classList.remove(this.iconClassClose);
                this.iconEl.classList.add(this.iconClassOpen);
            }
            if (typeof this.onClose === 'function') this.onClose(this);
        }
    }

    // Navigation container on mobile
    const navButton = document.querySelector('.nav-button');
    const navContainer = document.querySelector('.nav-container');
    new mobileNavHandler({
        button: navButton,
        container: navContainer,
    });

    // Main menu mega dropdowns on mobile
    const megaDropdowns = document.querySelectorAll('.mega-dropdown');
    Array.prototype.forEach.call(megaDropdowns, megaDropdown => {
        const megaButton = megaDropdown.querySelector(':scope > .megabutton');
        const megaMenu = megaDropdown.querySelector(':scope > .megamenu');

        if (!megaButton || !megaMenu) return;

        new mobileNavHandler({
            button: megaButton,
            container: megaMenu,
            iconSelector: null,
        });
    });

    // Filters and sorting mobile sections
    const filtersButton = document.querySelector('.filters-button');
    const filtersContainer = document.querySelector('.filters-container');
    const sortingButton = document.querySelector('.sorting-button');
    const sortingContainer = document.querySelector('.sorting-container');

    if (filtersButton && filtersContainer && sortingButton && sortingContainer) {
        const filtersNavHandle = new mobileNavHandler({
            button: filtersButton,
            container: filtersContainer,
            iconSelector: null,
        });

        const sortingNavHandle = new mobileNavHandler({
            button: sortingButton,
            container: sortingContainer,
            iconSelector: null,
        });

        filtersNavHandle.onOpen = () => sortingNavHandle.close();
        sortingNavHandle.onOpen = () => filtersNavHandle.close();
    }


    /**
     * Handling sticky header
     */
    class stickyHeaderHandler {
        constructor (element, offset = 0, className = 'stickied') {
            this.element = element;
            this.offset = offset;
            this.className = className;
            this.stickied = false;

            // Prepare space placeholder
            this.spacePlaceholder = document.createElement('div');
            this.spacePlaceholder.style.display = 'none';
            this.spacePlaceholder.className = 'space-placeholder';

            if(this.element){
                this.element.parentNode.insertBefore(this.spacePlaceholder, this.element);
                this.syncPlaceholderHeight();
                this.update();

                window.addEventListener('scroll', this.update.bind(this));
                window.addEventListener('resize', this.syncPlaceholderHeight.bind(this));
            }

        }

        update() {
            if (this.offset < window.scrollY) {
                this.stick();
            } else {
                this.unstick();
            }
        }

        stick() {
            if (this.stickied) return;

            this.stickied = true;
            this.element.classList.add(this.className);
            this.element.style.position = 'fixed';
            this.spacePlaceholder.style.display = 'block';
        }

        unstick() {
            if (!this.stickied) return;

            this.stickied = false;
            this.element.classList.remove(this.className);
            this.element.style.position = 'relative';
            this.spacePlaceholder.style.display = 'none';

            this.syncPlaceholderHeight();
        }

        syncPlaceholderHeight() {
            if (this.stickied) return;

            this.spacePlaceholder.style.height = this.element.offsetHeight + 'px';
        }
    }

    const mainHeader = document.querySelector('#main-header');
    new stickyHeaderHandler(mainHeader, 132, 'stickied');


 /*** GoogleTagManager global functions **/
    /**
     * Call this function when a user clicks on a product link. This function uses the event
     * callback datalayer variable to handle navigation after the ecommerce data has been sent
     * to Google Analytics.
     * @param {Object} productObj An object representing a product.
     */
    function GTMProductClicks(productObj) {

        dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
        dataLayer.push({
            'event': 'EECproductClick',
            'ecommerce': {
                'click': {
                    'actionField': {'list': productObj.list},
                    'products': [{
                            'name': productObj.name,
                            'id': productObj.id,
                            'price': productObj.price,
                            'brand': productObj.brand,
                            'category': productObj.cat,
                            'variant': productObj.variant,
                            'position': productObj.position
                        }]
                }
            },
            'eventCallback': function () {
                document.location = productObj.url
            }
        });
        
        //GA4 ecommerce tracking
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
          event: "select_item",
          ecommerce: {
            item_list_name: productObj.list,
            items: [
                {
                    item_id: productObj.id,
                    item_name: productObj.name,
                    affiliation: productObj.channel,
                    item_brand: productObj.brand,
                    item_category: productObj.cat,
                    price: parseFloat(productObj.price)
                }
            ]
          }
        });
    }

    function GTMProductImpressionsList() {
        
        var impressions = [];
        var currencyCode = $('#products').data('currency');
        var channel = $('#products').data('channel');
        var listType = $('#products').data('list');
        
        $('#products .product-list-item').each(function(i){
            var impression = {};
            impression.name = $(this).find('.product-list-item__title .gtm-product-link').data('name');
            impression.id = $(this).find('.product-list-item__title .gtm-product-link').data('id');
            impression.price = $(this).find('.product-list-item__title .gtm-product-link').data('price');
            impression.band = '';
            impression.category = $(this).find('.product-list-item__title .gtm-product-link').data('category');
            impression.variant = '';
            impression.list = listType;
            impression.position = i+1;
            
            impressions.push(impression);
        });
        
        dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
        dataLayer.push({
            'ecommerce': {
                'currencyCode': currencyCode, // Local currency is optional.
                'impressions': impressions
            }
        });
        
        //GA4
        let items = [];
            
        $('#products .product-list-item').each(function(i){
            let productObj = {};
            productObj.item_id = $(this).find('.product-list-item__title .gtm-product-link').data('id');
            productObj.item_name = $(this).find('.product-list-item__title .gtm-product-link').data('name');
            productObj.affiliation = channel;
            productObj.item_brand = '';
            productObj.item_category = $(this).find('.product-list-item__title .gtm-product-link').data('category');
            productObj.price = parseFloat($(this).find('.product-list-item__title .gtm-product-link').data('price'));

            items.push(productObj);
        });
        
        dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
        dataLayer.push({
            event: "view_item_list",
            ecommerce: {
                item_list_name: listType,
                items: items
            }
        });
    }
    
    function GTMProductImpressionsSliders(listType = 'Slider z najnowszymi produktami') {
        
        var impressions = [];
        var currencyCode = '';
        
        //Universal Analytics
        if( $('.products-latest-slider').length > 0 ){
            currencyCode = $('.products-latest-slider').data('currency');

            $('.products-latest-slider .swiper-slide-visible').each(function(i){
                var impression = {};
                impression.name = $(this).find('.sylius-product-name.gtm-product-link').data('name');
                impression.id = $(this).find('.sylius-product-name.gtm-product-link').data('id');
                impression.price = $(this).find('.sylius-product-name.gtm-product-link').data('price');
                impression.brand = '';
                impression.category = $(this).find('.sylius-product-name.gtm-product-link').data('category');
                impression.variant = '';
                impression.list = listType;
                impression.position = i+1;

                impressions.push(impression);
            });
        } else if ( $('.products-related-slider').length > 0 ) {
            currencyCode = $('.products-related-slider').data('currency');
            
            $('.products-related-slider .swiper-slide-visible').each(function(i){
                var impression = {};
                impression.name = $(this).find('.sylius-product-name.gtm-product-link').data('name');
                impression.id = $(this).find('.sylius-product-name.gtm-product-link').data('id');
                impression.price = $(this).find('.sylius-product-name.gtm-product-link').data('price');
                impression.brand = '';
                impression.category = $(this).find('.sylius-product-name.gtm-product-link').data('category');
                impression.variant = '';
                impression.list = listType;
                impression.position = i+1;

                impressions.push(impression);
            });
        }
        
        if( $('.products-latest-slider').length > 0 || $('.products-related-slider').length > 0 ){
            dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
            dataLayer.push({
                'ecommerce': {
                    'currencyCode': currencyCode, // Local currency is optional.
                    'impressions': impressions
                }
            });
        }
        
        //GA4
        let items = [];
        if( $('.products-latest-slider').length > 0 ){
            currencyCode = $('.products-latest-slider').data('currency');
            
            $('.products-latest-slider .swiper-slide-visible').each(function(i){
                let productObj = {};
                productObj.item_id = $(this).find('.sylius-product-name.gtm-product-link').data('id');
                productObj.item_name = $(this).find('.sylius-product-name.gtm-product-link').data('name');
                productObj.affiliation = $(this).find('.sylius-product-name.gtm-product-link').data('channel');
                productObj.item_brand = '';
                productObj.item_category = $(this).find('.sylius-product-name.gtm-product-link').data('category');
                productObj.price = parseFloat($(this).find('.sylius-product-name.gtm-product-link').data('price'));
                
                items.push(productObj);
            });
        } else if ( $('.products-related-slider').length > 0 ) {
            currencyCode = $('.products-related-slider').data('currency');
            
            $('.products-related-slider .swiper-slide-visible').each(function(i){
                let productObj = {};
                productObj.item_id = $(this).find('.sylius-product-name.gtm-product-link').data('id');
                productObj.item_name = $(this).find('.sylius-product-name.gtm-product-link').data('name');
                productObj.affiliation = $(this).find('.sylius-product-name.gtm-product-link').data('channel');
                productObj.item_brand = '';
                productObj.item_category = $(this).find('.sylius-product-name.gtm-product-link').data('category');
                productObj.price = parseFloat($(this).find('.sylius-product-name.gtm-product-link').data('price'));
                
                items.push(productObj);
            });
        }
        
        if( $('.products-latest-slider').length > 0 || $('.products-related-slider').length > 0 ){
            dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
            dataLayer.push({
                event: "view_item_list",
                ecommerce: {
                    item_list_name: listType,
                    items: items
                }
            });
        }
    }
    
    function GTMProductImpressionsSearchResults() {
        setTimeout(function () {
//            console.info('test search results');
        }, 5000);
    }

    function GTMOnCheckoutOption(step, checkoutOption) {
        dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
        dataLayer.push({
            'event': 'EECcheckoutOption',
            'ecommerce': {
                'checkout_option': {
                    'actionField': {'step': step, 'option': checkoutOption}
                }
            }
        });
    }

    /**
     * Cookie privacy policy
     */
    cookiesAccept();

    function cookiesAccept() {
    c();
    function a(e) {
        var f, d, h, g = document.cookie.split(";");
        for (f = 0; f < g.length; f++) {
            d = g[f].substr(0, g[f].indexOf("="));
            h = g[f].substr(g[f].indexOf("=") + 1);
            d = d.replace(/^\s+|\s+$/g, "");
            if (d == e) {
                return unescape(h)
            }
        }
    }

    function b() {
        var e = new Date();
        e.setDate(e.getDate() + 365);
        var d = escape("1") + ((365 == null) ? "" : "; expires=" + e.toUTCString());
        document.cookie = "cookies_info=" + d + ";path=/";
        document.getElementById("cookie_info").style.display = "none";
        document.getElementById("cookie_info_modal").style.display = "none";
        document.getElementById("cookie_info_full").style.display = "none";

        var googleScripts = document.querySelector('script[data-name="google-script"]');
        if( googleScripts ){
            for (var i = 0; i < googleScripts.length; ++i) {
                googleScripts[i].setAttribute('src', googleScripts[i].getAttribute('data-consent-src'));
            }
        }

        const cookiesAcceptedEvent = new Event('cookiesAcceptedEvent');
        window.dispatchEvent(cookiesAcceptedEvent);
    }

        function c() {
            var g = a("cookies_info");
            if (g == null || g == "") {
                $("#cookie_info").css("display", "block");
                $("#cookie_info_modal").css("display", "block");

                var d = document.getElementById("close_info_cookie");
                var pp = document.getElementById("close_info_cookie_2");
                if (d.addEventListener) {
                    d.addEventListener("click", function () {
                        b()
                    }, false)
                } else {
                    if (d.attachEvent) {
                        d.attachEvent("onclick", function () {
                            b()
                        })
                    }
                }
                if (pp.addEventListener) {
                    pp.addEventListener("click", function () {
                        b()
                    }, false)
                } else {
                    if (pp.attachEvent) {
                        pp.attachEvent("onclick", function () {
                            b()
                        })
                    }
                }
            } else {
                $("#cookie_info").css("display", "none");
                $("#cookie_info_modal").css("display", "none");
                $("#cookie_info_full").css("display", "none");

                var googleScripts = document.querySelector('script[data-name="google-script"]');
                if( googleScripts ){
                    for (var i = 0; i < googleScripts.length; ++i) {
                        googleScripts[i].setAttribute('src', googleScripts[i].getAttribute('data-consent-src'));
                    }
                }

                const cookiesAcceptedEvent = new Event('cookiesAcceptedEvent');
                window.dispatchEvent(cookiesAcceptedEvent);
            }
        }
    }
    ;

  /**
   * Common popup class
   */
  $('.ll-tooltip').popup({ hoverable: true });

})(jQuery);

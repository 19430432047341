import 'semantic-ui-css/components/api';
import $ from 'jquery';

$.fn.extend({
  addToCartAjax() {
    const element = this;
    const url = $(element).attr('action');
    const redirectUrl = $(element).data('redirect');
    const validationElement = $('#sylius-cart-validation-error');
    const $cartButton =  $('#sylius-cart-button');
    
    element.api({
      method: 'POST',
      on: 'submit',
      cache: false,
      url,
      beforeSend(settings) {
        /* eslint-disable-next-line no-param-reassign */
        settings.data = element.serialize();
        $cartButton.addClass('loading');

        return settings;
      },
      onSuccess(response) {
        validationElement.addClass('hidden');
        
        $.ajax({
            method: "POST",
            url: '/loadcurrentcartwidget',
            dataType: 'json'
        })
        .done(function (response) {
            if (response.button) {
                $cartButton.html(response.button);
            }
        });
            
        $cartButton.removeClass('loading');
        
      },
      onFailure(response) {
        $cartButton.removeClass('loading');
        validationElement.removeClass('hidden');
        let validationMessage = '';

        Object.entries(response.errors.errors).forEach(([, message]) => {
          validationMessage += message;
        });
        validationElement.html(validationMessage);
        $(element).removeClass('loading');
        
      },
    });
  },
});
